<template>
  <div class="add-cart">
    <div class="c-main">
      <div class="c-txt">
        <div class="t-icon">
          <i class="fa fa-check"></i>
        </div>
        <div class="t-title">商品已成功加入购物车！</div>
      </div>
      <div class="c-goods">
        <div class="g-img">
          <img :src="info.img" alt="" />
        </div>
        <div class="g-info">
          <div class="g-name">
            <a href="#">
              {{info.goods_name}}
            </a>
          </div>
          <div class="g-number">数量:{{num}}</div>
        </div>
      </div>
      <div class="c-btn">
        <div class="b-info b-item" @click="$router.push('/store/goods?id='+$route.query.id);">查看商品详情</div>
        <div class="b-goAccount b-item" @click="$router.push('/home/shoppingCart');">
          <span>去购物车结算</span>
          <i class="fa fa-angle-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGoodsInfo } from '@/api/goods'
export default {
  data(){
    return{
      id:0,
      num:0,
      info:[]
    }
  },
  methods: {
    async getinfo(){ // 获取商品详情信息,初始化数据
      const {data: res} = await getGoodsInfo({id:this.id});
      var tem = res.result;
      this.info     = tem.info;
    },
  },
  created() {
    this.id  = this.$route.query.id;
    this.num = this.$route.query.num;
    this.getinfo()
  } 
}
</script>

<style lang="less" scoped>
.add-cart {
  min-height: 120px;
  background: #f5f5f5;
  padding-bottom: 15px;
  .c-main {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    .c-txt {
      padding: 20px 0px;
      display: flex;
      .t-icon {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 2px solid #7abd54;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          font-size: 14px;
          color: #7abd54;
        }
      }
      .t-title {
        line-height: 25px;
        font-size: 16px;
        color: #7abd54;
        margin-left: 10px;
      }
    }
    .c-goods {
      width: 592px;
      height: 62px;
      display: flex;
      margin-bottom: 10px;

      .g-img {
        width: 60px;
        height: 60px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .g-info {
        padding: 5px 0px 5px 10px;
        width: 520px;
        .g-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          a {
            color: #333;
            font-size: 14px;
          }
        }
        .g-number {
          padding-top: 5px;
          color: #666;
        }
      }
    }
    .c-btn {
      position: absolute;
      bottom: 0px;
      right: 0px;
      .b-item {
        width: 158px;
        height: 36px;
        font-size: 16px;
        line-height: 36px;
        text-align: center;
        float: left;
      }
      .b-info {
        border: 1px solid transparent;
        background: #fff;
        color: #e1251b;
        cursor:pointer;
        &:hover {
          border: 1px solid #e1251b;
        }
      }
      .b-goAccount {
        position: relative;
        margin-left: 10px;
        background: #e1251b;
        cursor:pointer;
        color: #fff;
        i {
          position: absolute;
          top: 3px;
          right: 12px;
          font-size: 28px;
        }
      }
    }
  }
}
</style>