import request from '@/libs/request'

/**
 * 获取商品信息
 */
export function getGoodsInfo(data) {
  return request({
    url: '/goods/info',
    method: 'post',
    data
  })
}

/**
 * 搜索商品
 */
 export function GoodsSearch(data) {
  return request({
    url: '/goods/search',
    method: 'post',
    data
  })
}