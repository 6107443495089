<template>
  <div class="order-confirm">
    <div class="c-container">
      <div class="c-header">
        <div class="h-item">
          <i class="fa fa-shopping-cart"></i>
          <span>我的购物车</span>
        </div>
        <div class="h-item h-current">
          <i class="fa fa-truck"></i>
          <span>选择收货地址</span>
        </div>
        <div class="h-item">
          <i class="iconfont icon-xuanzezhifufangshi"></i>
          <span>选择支付方式</span>
        </div>
        <div class="h-item">
          <i class="iconfont icon-zhifuchenggong"></i>
          <span>支付成功</span>
        </div>
      </div>
      <!--收货地址 -->
      <div class="c-address" v-loading="loading">
        <div class="a-title">选择收货地址</div>
        <div class="a-list clearfix">
          <div class="l-item" v-for="(item,index) in addresslist" :key="index" @click="addrdefault(index)">
            <div class="l-item1" :class="item.is_default==1? 'l-current' : ''">
              <div class="addr-hd">
                <span v-for="(addr,key) in item.merger_code_name" :key="key">{{addr}}&nbsp;&nbsp;</span>
                <span>({{item.real_name}}收)</span>
              </div>
              <div class="addr-bd">
                <span>{{item.detail}}</span>
                &nbsp;
                <span>{{item.phone}}</span>
                &nbsp;
              </div>
              <a @click.stop="addredit(item)">修改</a>
            </div>
          </div>
          <div class="l-addAddr" @click="addAddress">
            <img src="../../assets/images/add.png"/>
            <div class="a-txt">添加新地址</div>
          </div>
        </div>
        <!-- 添加新收货地址弹出框 -->
        <el-dialog title="收货地址" :visible.sync="dialogVisible" width="700px" :before-close="handleClose">
          <el-form ref="addressFormRef" :model="addressFormData" :rules="addressFormRules">
            <el-row type="flex" :gutter="24">
              <el-col>
                <el-form-item prop="real_name">
                  <el-input placeholder="姓名" v-model="addressFormData.real_name"></el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item prop="phone">
                  <el-input placeholder="手机号码" v-model="addressFormData.phone"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-form-item prop="area">
                <el-cascader v-model="addressFormData.city_id" :options="areaList" placeholder="请选择省/市/区/街道" style="width: 100%">
                </el-cascader>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item prop="detail">
                <el-input type="textarea" :rows="2" placeholder="详细地址" v-model="addressFormData.detail" style="width: 100%">
                </el-input>
              </el-form-item>
            </el-row>
            <!-- 是否为默认 -->
            <el-row>
              <el-form-item prop="is_default">
                <el-checkbox v-model="addressFormData.is_default" true-label=1 false-label=0>设为默认</el-checkbox>
              </el-form-item>
            </el-row>
          </el-form>
          <!-- 取消/确定 -->
          <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="addrdowm()" v-if="addreditch">保存</el-button>
            <el-button type="primary" @click="addto()" v-else>提交</el-button>
          </span>
        </el-dialog>
        <!-- 支付方式 -->
        <div class="payment">
          <div class="p-title">支付方式</div>
          <div class="p-list">
            <div class="p-item" :class="payment==1? 'p-current' : ''" @click="payment = 1">
              <div class="p-weixin">
                <i class="fa fa-weixin"></i>
                &nbsp;
                <span>微信支付</span>
              </div>
            </div>
            <div class="p-item" :class="payment==2? 'p-current' : ''" @click="payment = 2">
              <div class="p-alipay">
                <i class="iconfont icon-alipay1"></i>
                &nbsp;
                <span>支付宝支付</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 订单信息-->
        <div class="order-info">
          <div class="i-title">订单信息</div>
          <div class="i-hd">
            <div class="h-name h-item">店铺宝贝</div>
            <div class="h-attr h-item">商品规格</div>
            <div class="h-price h-item">单价</div>
            <div class="h-num h-item">数量</div>
            <div class="h-sum h-item">小计</div>
          </div>

          <div class="i-list" v-for="(item,index) in goodsList.list" :key="index">
            <div class="l-store">
              店铺名称: &nbsp;<a href="#">{{item.store_name}}</a>
            </div>
            <!-- 商品列表 -->
            <div class="goods-list" v-for="(goods,id) in item.goodslist" :key="id">
              <div class="l-item">
                <div class="l-goods">
                  <div class="g-img">
                    <a :href="'/store/goods?id='+goods.goods_id" target="_blank"><img :src="goods.img" /></a>
                  </div>
                  <div class="g-msg pl10">
                    <a :href="'/store/goods?id='+goods.goods_id" target="_blank">{{goods.name}}</a>
                  </div>
                </div>
                <div class="l-props">
                  <div class="props-txt pt10" v-for="(attr,key) in goods.attr" :key="key">{{attr}}</div>
                </div>
                <div class="l-price">￥{{goods.price}}</div>
                <div class="l-num">
                  <span>{{goods.num}}</span>
                </div>
                <div class="l-sum">
                  <strong>￥{{goods.total}}</strong>
                </div>
              </div>
            </div>
            <div class="i-main">
              <div class="m-checked m-item">
                <span>给卖家留言:</span>
                <el-input type="textarea" :rows="2" placeholder="选填,请先和商家协商一致" class="m-input" maxlength="200" show-word-limit v-model="item.remarks">
                </el-input>
              </div>
              <div class="m-install m-item"></div>
            </div>
            <div class="i-footer">
              <span>店铺合计</span>&nbsp;&nbsp;&nbsp;<strong>￥{{item.totalprice}}</strong>
            </div>
          </div>
        </div>

        <!-- 结算 -->
        <div class="f-account">
          <div class="f-hd">
            <div class="f-money">
              <span class="m-txt">实付款：</span>
              <b>
                <span class="m-icon"></span>
                <span>¥{{goodsList.totalprice}}</span>
              </b>
            </div>
            <div class="f-address" v-if="defaultaddr.merger_code_name">
              <b>寄送至 : </b>
              <span><label v-for="(addr,key) in defaultaddr.merger_code_name" :key="key">{{addr}}&nbsp;</label>{{defaultaddr.detail}}</span>
              <div class="f-name">
                <b>收货人：</b>
                <span>{{defaultaddr.real_name}} {{defaultaddr.phone}}</span>
              </div>
            </div>
            <div v-else>
              <div class="f-name">
                <b>提示：</b>
                <span>请选择收货地址</span>
              </div>
            </div>
          </div>
          <div class="f-ft">
            <div class="f-back">
              <a :href="'/home/shoppingCart'">
                <i class="iconfont icon-back"></i><span>返回购物车</span>
              </a>
            </div>
            <div class="f-btn" @click="OrderUp()">提交订单</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { OrderConfirm, OrderPost } from '@/api/order'
  import { aliPayPage } from '@/api/payment'
  import { getAddressListApi, getAreaListApi, editUserLabelApi, getAddressedit, postAddress } from '@/api/user';
  export default {
    name: "orderConfirm",
    data() {
      return {
        payment: 1, // 支付方式
        loading:false,
        addreditch: false,
        addressList: [],
        dialogVisible: false,
        addressFormData: {
          real_name: "", // 姓名
          phone: "",     // 手机号码
          city_id: '',   // 地区列表
          detailAddress: "", //详细地址
          is_default: ""
        },
        areaList: [],   //获取地区列表数据
        addresslist: [],
        defaultaddr: [],
        goodsList: [],
        textarea: '',
        addressFormRules: { //添加收货地址验证规则
          real_name: [
            { required: true, message: '请输入收货人姓名', trigger: 'blur' },
          ],
          phone: [
            { required: true, message: '请输入收货人电话号码', trigger: 'blur' },
            { pattern: /^1[3456789]\d{9}$/, message: '请填写正确的手机号码' }
          ],
          detail: [
            { required: true, message: '请输入详细地址', trigger: 'blur' },
          ],
        },
      }
    },
    methods: {
      async OrderInfo() { // 初始化数据 收货地址 商品列表
        this.loading = true
        const { data: res } = await OrderConfirm()
        if (res.status == 90000) { this.Toerror(); return false; }
        if (res.message == "ok") {
          this.goodsList = res.result.goodsList;
          this.addresslist = res.result.addressList;
          for (var item in this.addresslist) {
            if (this.addresslist[item].is_default == 1) {
              this.defaultaddr = this.addresslist[item];
              break;
            }
          }
        }
        this.loading = false
      },
      async OrderUp() { // 订单提交
        if (this.defaultaddr.length == 0) { this.$message('请明确收货地址！'); return false; }
        if (this.payment <= 0) { this.$message('请选择支付方式！'); return false; }
        this.loading = true
        const { data: res } = await OrderPost({ address: this.defaultaddr, payment: this.payment, goodsList: this.goodsList.list });
        if (res.status == 90000) { this.Toerror(); return false; }
        if (res.message == 'ok') {
          if (this.payment == 1) {
            this.$router.push('/home/wxPay?id=' + res.result);
            return false;
          }else if(this.payment==2){
            const res1 = await aliPayPage(res.result);
            const div = document.createElement('div')
            /* 下面的data.content就是后台返回接收到的数据 */
            div.innerHTML = res1.data.result.form
            document.body.appendChild(div)
            document.forms[0].acceptCharset = 'UTF-8'
            document.forms[0].submit()
          }
        }
        this.loading = false;
      },
      addrdefault(index) { // 选择收货地址
        for (var item in this.addresslist) {
          this.addresslist[item].is_default = 0
          if (item == index) {
            this.addresslist[item].is_default = 1
            this.defaultaddr = this.addresslist[item];
          }
        }
      },
      addredit(item) { // 收货地址修改 弹窗打开
        this.addreditch = true;
        this.dialogVisible = true
        if (this.areaList.length == 0) { // 如果没有城市列表请求城市列表
          getAreaListApi().then(res => { this.areaList = res.data.result.cityInfo })
        }
        this.addressFormData = {
          "address_id": item.address_id,
          "real_name": item.real_name,
          "phone": item.phone,
          "detail": item.detail,
          "post_code": item.post_code,
          "is_default": item.is_default,
          "city_id": 0
        };
        var tem = new Array(); // 确认城市ID数组
        for (var ider in item.merger_code_name) { tem.push(ider); }
        this.addressFormData.city_id = tem
      },
      addAddress() { // 收货地址添加弹窗打开
        this.addreditch = false;
        if (this.areaList.length == 0) { // 如果没有城市列表请求城市列表
          getAreaListApi().then(res => { this.areaList = res.data.result.cityInfo })
        }
        this.addressFormData = { "real_name": '', "phone": '', "detail": '', "post_code": '', "is_default": 0, "city_id": 0 };
        this.dialogVisible = true
      },
      async addrdowm() { // 收货地址【修改】提交
        this.$refs.addressFormRef.validate((valid) => {
          if (!valid) return false;
          if (this.addressFormData.city_id.length != 3) { // 如果没有选中省市区
            this.$message('请选择省市区');
            return false;
          }
          this.addressFormData.city_id = this.getcityid(this.addressFormData.city_id);

          let formData = "" // 组装提交数据
          for (var item in this.addressFormData) { formData += `${item}=${this.addressFormData[item]}&` }
          formData = formData.slice(0, formData.length - 1)
          this.loading = true
          getAddressedit(this.addressFormData.address_id, formData).then(res => { // 提交修改数据
            if (res.data.message == "ok") {
              this.addresslist = new Array();
              this.addresslist = res.data.result;
              for (var item in this.addresslist) {
                if (this.addresslist[item].is_default == 1) {
                  this.defaultaddr = this.addresslist[item];
                }
              }
              this.dialogVisible = false;
            } else {
              this.$message(res.data.result);
            }
          })
          this.loading = false
        });
      },
      async addto() { // 收货地址【添加】提交
        this.$refs.addressFormRef.validate((valid) => {
          if (!valid) return false;
          if (this.addressFormData.city_id.length != 3) { // 如果没有选中省市区
            this.$message('请选择省市区');
            return false;
          }
          this.addressFormData.city_id = this.getcityid(this.addressFormData.city_id);
          this.loading = true
          postAddress(this.addressFormData).then(res => {
            if (res.data.message == "ok") {
              this.addresslist = new Array();
              this.addresslist = res.data.result;
              for (var item in this.addresslist) {
                if (this.addresslist[item].is_default == 1) {
                  this.defaultaddr = this.addresslist[item];
                }
              }
              this.dialogVisible = false;
            } else {
              this.$message(res.data.result);
            }
          })
          this.loading = true
        });
      },
      getcityid(arr) { // 通过遍历城市列表确认最终收货地址城市ID
        for (var item in this.areaList) {
          var cheack = false;
          if (this.areaList[item].value == arr[0]) {
            for (var titem in this.areaList[item].children) {
              if (this.areaList[item].children[titem].value == arr[1]) {
                for (var ttitem in this.areaList[item].children[titem].children) {
                  if (this.areaList[item].children[titem].children[ttitem].value == arr[2]) {
                    return this.areaList[item].children[titem].children[ttitem].city_id;
                  }
                }
              }
            }
          }
        }
      },
      handleClose() {
        this.dialogVisible = false
      },
      Toerror() {
        this.$router.push('/home/error?mes=服务器繁忙，请稍后在试');
      }
    },
    created() {
      this.OrderInfo();
    }
  }
</script>

<style lang="less" scoped>
  .order-confirm {
    width: 1200px;
    margin: 0 auto;

    .c-container {
      // min-height: 1000px;
      // border: 1px solid red;
      margin: 20px 0px;

      .c-header {
        height: 46px;

        .h-item {
          float: left;
          width: 280px;
          height: 46px;
          background: #f5f7fa;
          font-size: 16px;
          color: #333;
          line-height: 46px;
          text-align: center;
          font-weight: 500;
          margin-right: 20px;

          span {
            margin-left: 8px;
          }
        }

        .h-current {
          color: #fff;
          background: #e1251b;
        }
      }

      .c-address {
        margin: 40px 0px 0px;

        .a-title {
          font-size: 16px;
          color: #333;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .a-list {
          .l-item {
            .l-item1 {
              box-sizing: border-box;
              float: left;
              width: 280px;
              height: 105px;
              border: 3px solid #efefef;
              border-radius: 3px;
              margin-bottom: 10px;
              margin-right: 20px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 5px 10px;
              cursor: pointer;
              border-radius: 5px;

              &:hover {
                border: 3px solid #e1251b;
              }

              .addr-hd {
                height: 18px;
                border-bottom: 1px solid #efefef;
                line-height: 18px;
                font-weight: 700;
                color: #666;
                padding-bottom: 5px;
              }

              .addr-bd {
                color: #666;
              }

              a {
                color: #d69977;
              }
            }

            .l-current {
              background: url("../../assets/images/opt.png") no-repeat;
              background-position: right bottom;
              border: 2px solid #e1251b;
            }
          }

          .l-addAddr {
            float: left;
            width: 280px;
            height: 105px;
            border: 3px solid #efefef;
            border-radius: 3px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            cursor: pointer;

            img {
              width: 27px;
              height: 27px;
            }

            .a-txt {
              color: #666;
            }
          }

          .l-addAddr:hover {
            border: 3px solid #e1251b;
          }
        }
      }

      .payment {
        margin: 15px 0px 20px;
        height: 100px;
        border-top: 1px solid #efefef;
        border-bottom: 1px solid #efefef;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .p-title {
          font-size: 16px;
          font-weight: 700;
        }

        .p-item {
          width: 100px;
          height: 40px;
          border: 2px solid #efefef;
          line-height: 40px;
          text-align: center;
          float: left;
          margin-right: 20px;
          border-radius: 5px;
          cursor: pointer;

          .p-weixin {
            color: #666;

            i {
              color: #06b259;
            }
          }

          .p-alipay {
            color: #666;

            i {
              color: #1677ff;
            }
          }

          &:hover {
            border: 2px solid #e1251b;
          }
        }

        .p-current {
          background: url("../../assets/images/opt.png") no-repeat;
          background-position: right bottom;
          border: 2px solid #e1251b;
        }
      }

      .order-info {
        margin: 20px 0px;

        .i-title {
          font-size: 16px;
          color: #333;
          font-weight: bold;
        }

        .i-hd {
          display: flex;
          justify-content: space-between;
          // font-size: 14px;
          color: #666;
          margin: 10px 0px;

          .h-item {
            height: 24px;
            line-height: 24px;
            text-align: center;
            border-bottom: 3px solid #b2d1ff;
          }

          .h-name {
            width: 450px;
          }

          .h-attr {
            width: 300px;
          }

          .h-price {
            width: 130px;
          }

          .h-num {
            width: 130px;
          }

          .h-sum {
            width: 130px;
          }
        }

        .i-list {
          margin-top: 30px;

          .l-store {
            border-bottom: 1px dashed #b2d1ff;
            padding-bottom: 10px;
            margin-top: 20px;
          }

          .goods-list {
            height: 100px;
            border-bottom: 1px dashed #d3d3d3;
            box-sizing: border-box;

            .l-item {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .l-goods {
                display: flex;
                width: 450px;
                padding: 5px 0px;

                .g-img {
                  width: 90px;
                  height: 90px;

                  img {
                    width: 90px;
                    height: 90px;
                  }
                }

                .g-msg {
                  padding-top: 5px;

                  a {
                    color: #333;
                  }

                  a:hover {
                    color: #e1251b;
                  }
                }
              }

              .l-props {
                width: 300px;
                text-align: center;
                color: #a5a5a5;
              }

              .l-price {
                width: 130px;
                text-align: center;
              }

              .l-num {
                width: 130px;
                text-align: center;

                .l-install {
                  margin-top: 10px;
                }
              }

              .l-sum {
                width: 130px;
                color: #e1251b;
                text-align: center;
              }
            }
          }

          .i-main {
            min-height: 75px;
            margin-top: 2px;
            display: flex;
            justify-content: space-between;

            .m-item {
              width: 599px;
              min-height: 75px;
              background: #f2f7ff;
              box-sizing: border-box;
              padding: 10px 10px;
            }

            .m-checked {
              .m-input {
                margin-left: 10px;
                width: 80%;
                height: 50%;
                font-size: 12px;
                position: relative;

                /deep/ .el-input__count {
                  position: absolute;
                  right: 12px;
                  top: 28px;
                }
              }
            }

            .m-install {}
          }

          .i-footer {
            height: 50px;
            background: #f2f7ff;
            margin-top: 2px;
            display: flex;
            justify-content: flex-end;
            line-height: 50px;
            font-size: 14px;
            padding-right: 43px;
            color: #333;

            strong {
              color: #e1251b;
            }
          }
        }
      }

      .f-account {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .f-hd {
          width: 380px;
          height: 120px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-end;
          border: 1px solid #e1251b;
          padding-right: 10px;

          .f-money {
            .m-txt {
              font-weight: 700;
              font-size: 14px;
              color: #333;
            }

            b {
              font-size: 26px;
              color: #e1251b;
              font-weight: 700;

              .m-icon {
                margin-right: 5px;
                color: #999;
                font-weight: normal;
              }
            }
          }

          .f-address {
            .f-name {
              text-align: right;
              margin-top: 5px;
            }

            span {
              color: #333;
            }
          }
        }

        .f-ft {
          display: flex;
          position: relative;

          .f-back {
            height: 40px;
            text-align: center;
            line-height: 40px;
            width: 100px;

            i {
              color: #d69977;
              font-size: 20px;
              position: absolute;
              top: 1px;
              left: -5px;
            }

            span {
              color: #d69977;
            }
          }

          .f-btn {
            width: 180px;
            height: 40px;
            background-color: #e1251b;
            color: #fff;
            line-height: 40px;
            text-align: center;
            margin-left: 30px;
            font-size: 14px;
            font-weight: 700;
            cursor: pointer;
          }
        }
      }
    }
  }
</style>
